define("moments/models/session", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    associatedTraitIds: attr('array'),
    authToken: attr('string'),
    browserResolution: attr('string'),
    campaign: belongsTo('campaign'),
    forceLogout: attr('boolean'),
    isDemo: attr('boolean', {
      defaultValue: false
    }),
    isDraft: attr('boolean', {
      defaultValue: false
    }),
    isViewed: attr('boolean', {
      defaultValue: true
    }),
    iterationId: attr('string'),
    node: belongsTo('node'),
    referralLink: attr('string'),
    responses: hasMany('response'),
    uid: attr('string'),
    urlParams: attr('string'),
    utcOffset: attr('string'),
    outcome: null,
    isPreviewing: Ember.computed.or('isDemo', 'isDraft')
  });

  _exports.default = _default;
});