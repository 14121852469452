define("moments/services/session-creator", ["exports", "jquery", "moment"], function (_exports, _jquery, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    queryParams: Ember.inject.service(),
    store: Ember.inject.service(),
    create: function create(campaign) {
      var sessionData = this.get('sessionData');
      sessionData.campaign = campaign;
      sessionData.iterationId = campaign.get('iterationId');
      return this.get('store').createRecord('session', sessionData).save();
    },
    sessionData: Ember.computed(function () {
      var queryParams = this.get('queryParams');
      var isDemo = queryParams.findParam('preview');
      var isDraft = queryParams.findParam('draft');
      var isViewed = !queryParams.findParam('deferred');
      var uid = this.getUid();
      var browserResolution = this.calculateBrowserResolution();
      var referralLink = document.referrer;
      var urlParams = this.getUrlParams();
      var forceLogout = queryParams.removeParam('jl');
      var utcOffset = (0, _moment.default)().utcOffset() / 60;
      return {
        forceLogout: forceLogout,
        isDemo: isDemo,
        isDraft: isDraft,
        isViewed: isViewed,
        uid: uid,
        browserResolution: browserResolution,
        referralLink: referralLink,
        urlParams: urlParams,
        utcOffset: utcOffset
      };
    }),
    getUid: function getUid() {
      var queryParams = this.get('queryParams');
      var validUidParameters = ['uid', 'jebbit-email', 'mi_u', 'customerId', 'device_id', 'et_rid', 'user-id', 'PSN', 'cID'];
      var id;

      for (var i = 0; i < validUidParameters.length; i++) {
        var parameter = validUidParameters[i];
        var value = queryParams.findParam(parameter);

        if (value) {
          id = value;
          break;
        }
      }

      if (id) {
        try {
          return decodeURIComponent(id).replace(' ', '+');
        } catch (e) {
          return id;
        }
      }
    },
    getUrlParams: function getUrlParams() {
      var launchLinkRegex = /[\?\&]L=/;
      var hardcodedLaunchLink = (0, _jquery.default)('meta[name="launch-link"]').attr('content');
      var urlParams = window.location.search;

      if (hardcodedLaunchLink && !launchLinkRegex.test(urlParams)) {
        if (Ember.isEmpty(urlParams)) {
          urlParams = "?".concat(hardcodedLaunchLink);
        } else {
          urlParams += "&".concat(hardcodedLaunchLink);
        }
      }

      return urlParams;
    },
    calculateBrowserResolution: function calculateBrowserResolution() {
      var winHeight = Math.max(document.body.scrollHeight, document.documentElement.scrollHeight, document.body.offsetHeight, document.documentElement.offsetHeight, document.body.clientHeight, document.documentElement.clientHeight);
      var winWidth = Math.max(document.body.scrollWidth, document.documentElement.scrollWidth, document.body.offsetWidth, document.documentElement.offsetWidth, document.body.clientWidth, document.documentElement.clientWidth);
      return "".concat(winWidth, "x").concat(winHeight);
    }
  });

  _exports.default = _default;
});