define("moments/services/outcome-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    calculateOutcome: function calculateOutcome(session, feedOutcome) {
      var outcomeGroup = session.get('campaign.outcomeGroup');
      var format = outcomeGroup.get('format');
      var outcome;

      if (session.get('outcome')) {
        return session.get('outcome');
      }

      if (format === 'Standard') {
        outcome = this.handleStandardOutcome(outcomeGroup, session);
      } else if (format === 'Cumulative') {
        outcome = this.handleCumulativeOutcome(outcomeGroup, session);
      } else if (format === 'Feed') {
        outcome = feedOutcome.feed_outcome;
      }

      if (!outcome) {
        outcome = this.getRandomOutcome(outcomeGroup.get('outcomes'));
      }

      this.saveOutcome(outcome, session, format);
      return outcome;
    },
    getRandomOutcome: function getRandomOutcome(outcomes) {
      if (!outcomes) {
        return;
      }

      var numOutcomes = outcomes.get('length');
      var index = Math.floor(Math.random() * numOutcomes);
      return outcomes.objectAt(index);
    },
    handleStandardOutcome: function handleStandardOutcome(outcomeGroup, session) {
      var outcomes = outcomeGroup.get('outcomes');
      var tallies = {};
      var potentialOutcomes = [];
      var currentMax = -1;
      session.get('responses').forEach(function (response) {
        var edgeOutcomes = response.get('edge.edgeOutcomes') || [];
        edgeOutcomes.forEach(function (edgeOutcome) {
          var outcomeId = edgeOutcome.get('outcome.id');
          tallies[outcomeId] = tallies[outcomeId] || 0;
          tallies[outcomeId] += edgeOutcome.get('pointValue');
        });
      });
      Object.keys(tallies).forEach(function (outcomeId) {
        var score = tallies[outcomeId];

        if (score > currentMax) {
          potentialOutcomes = [outcomes.findBy('id', outcomeId)];
          currentMax = score;
        } else if (score === currentMax) {
          potentialOutcomes.push(outcomes.findBy('id', outcomeId));
        }
      }); // Randomizes the outcome given a tie. If only 1 exists, it will always be chosen

      return this.getRandomOutcome(potentialOutcomes);
    },
    handleCumulativeOutcome: function handleCumulativeOutcome(outcomeGroup, session) {
      var outcomes = outcomeGroup.get('outcomes').sortBy('threshold');
      var potentialOutcomes = [];
      var tally = 0;
      var currentMaxThreshold = outcomes.get('firstObject').get('threshold');
      var i, outcome;
      session.get('responses').forEach(function (response) {
        var edgeOutcomes = response.get('edge.edgeOutcomes') || [];
        edgeOutcomes.forEach(function (edgeOutcome) {
          tally += edgeOutcome.get('pointValue');
        });
      });

      for (i = 0; i < outcomes.length; i++) {
        outcome = outcomes.objectAt(i);
        var threshold = outcome.get('threshold');

        if (tally >= threshold) {
          if (threshold == currentMaxThreshold) {
            //will always push first outcome
            potentialOutcomes.push(outcome);
          } else if (threshold > currentMaxThreshold) {
            potentialOutcomes = [outcome];
            currentMaxThreshold = threshold;
          }
        } else {
          break;
        }
      }

      if (potentialOutcomes) {
        return this.getRandomOutcome(potentialOutcomes);
      } else {
        return outcomes.get('firstObject');
      }
    },
    checkForFeedOutcome: function checkForFeedOutcome(childNode, session) {
      if (session.get('campaign.outcomeGroup.format') === 'Feed') {
        var nextPossibleEdges = childNode.get('edges');
        var resourceTypes = [];

        if (nextPossibleEdges) {
          resourceTypes = nextPossibleEdges.mapBy('childNode.resourceType');
        }

        return resourceTypes.indexOf('Outcome') > -1;
      }
    },
    handleFeedOutcome: function handleFeedOutcome(session) {
      var etrs = this.store.peekAll('edge-trait-response');
      var sessionResponseEdgeIds = session.get('responses').mapBy('edge.id');
      var etrsTraversedInSession = [];
      etrs.forEach(function (etr) {
        if (sessionResponseEdgeIds.indexOf(etr.get('edge.id')) !== -1) {
          etrsTraversedInSession.push(etr);
        }
      });
      return this.get('ajax').request('GET', 'feed_outcomes', {
        query: {
          traitResponseIds: etrsTraversedInSession.mapBy('traitResponseId'),
          sessionId: session.get('id')
        }
      });
    },
    saveOutcome: function saveOutcome(outcome, session, format) {
      if (format !== 'Feed') {
        session.set('outcome', outcome);
        this.get('store').createRecord('session-outcome', {
          session: session,
          outcome: outcome
        }).save();
      } else {
        var feedRowId = outcome.id;
        var feedId = outcome.feed_id;
        this.get('store').createRecord('session-outcome', {
          session: session,
          feedRowId: feedRowId,
          feedId: feedId
        }).save();
      }
    }
  });

  _exports.default = _default;
});