define("moments/mixins/has-session-mixin", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;

  var _default = Ember.Mixin.create({
    authToken: attr('string'),
    session: belongsTo('session'),
    ready: function ready() {
      this._super.apply(this, arguments);

      this.set('authToken', this.get('session.authToken'));
    }
  });

  _exports.default = _default;
});