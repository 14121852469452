define("moments/components/content-piece/image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sZkq4m/J",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"contentPiece\",\"jsonValue\",\"link\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[12,\"href\",[25,[\"contentPiece\",\"jsonValue\",\"link\"]]],[11,\"target\",\"_blank\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[30,[[23,\"imageClass\"]]]],[12,\"style\",[23,\"imageStyle\"]],[9],[0,\"\\n      \"],[7,\"img\"],[12,\"src\",[30,[[23,\"imageSrc\"]]]],[11,\"class\",\"hidden-image\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[23,\"imageClass\"]]]],[12,\"style\",[23,\"imageStyle\"]],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"src\",[30,[[23,\"imageSrc\"]]]],[11,\"class\",\"hidden-image\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/image/template.hbs"
    }
  });

  _exports.default = _default;
});