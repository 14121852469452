define("moments/components/comment-icon/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RXGKpilF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[\"messages \",[29,\"if\",[[25,[\"showComments\"]],\"active\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[29,\"gt\",[[25,[\"numComments\"]],0],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"num-comments\"],[9],[1,[23,\"numComments\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"button\"],[11,\"class\",\"basic-button\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"material-icons messages-icon\"],[9],[1,[23,\"icon\"],false],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"clickToggle\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/comment-icon/template.hbs"
    }
  });

  _exports.default = _default;
});