define("moments/components/layouts/base-layout/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['full-area', 'screen'],
    classNameBindings: ['screenClass', 'contentCollection.isNext:next', 'isActive:active:hidden', 'commentsVisible:comments-visible', 'forceDisplay:force-display', 'cssVersion'],

    /*------------ Variables ------------*/
    commentsVisible: false,
    contentCollection: null,
    currentProgress: 0,
    layoutPartNames: ['content-block-primary', 'content-block-secondary'],
    logo: null,
    maxDepth: 1,
    outcome: null,
    userHasResponded: false,
    imageOverrideLayout: true,
    imageOverridePrimary: true,
    imageOverrideSecondary: true,

    /*-------- Bubbly functions ---------*/
    onButtonClick: function onButtonClick() {},
    onFormSubmission: function onFormSubmission() {},
    onRenderScreen: function onRenderScreen() {},
    onShare: function onShare() {},

    /*------- Computed properties -------*/
    contentPieces: Ember.computed.alias('contentCollection.contentPieces'),
    outcomeClass: Ember.computed('outcome', function () {
      var id = this.get('outcome.id');

      if (id) {
        return "outcome-".concat(id);
      }
    }),
    layoutBackgroundVideoContentPiece: Ember.computed('contentPieces.[]', function () {
      return this.findBackgroundVideo('layout-bg-video');
    }),
    layoutBackgroundVideoExists: Ember.computed('layoutBackgroundVideoContentPiece', 'imageOverrideLayout', function () {
      return this.get('layoutBackgroundVideoContentPiece') && !this.get('imageOverrideLayout');
    }),
    primaryBackgroundVideoContentPiece: Ember.computed('contentPieces.[]', function () {
      return this.findBackgroundVideo('content-block-primary-bg-video');
    }),
    primaryBackgroundVideoExists: Ember.computed('primaryBackgroundVideoContentPiece', 'imageOverridePrimary', function () {
      return this.get('primaryBackgroundVideoContentPiece') && !this.get('imageOverridePrimary');
    }),
    screenClass: Ember.computed('contentCollection.id', function () {
      var id = this.get('contentCollection.id');
      return "screen-".concat(id);
    }),
    secondaryBackgroundVideoContentPiece: Ember.computed('contentPieces.[]', function () {
      return this.findBackgroundVideo('content-block-secondary-bg-video');
    }),
    secondaryBackgroundVideoExists: Ember.computed('secondaryBackgroundVideoContentPiece', 'imageOverrideSecondary', function () {
      return this.get('secondaryBackgroundVideoContentPiece') && !this.get('imageOverrideSecondary');
    }),
    sortedContentBlocks: Ember.computed('contentPieces.[]', function () {
      var contentPieces = this.get('contentPieces') || [];
      var layoutPartNames = this.get('layoutPartNames');
      return layoutPartNames.map(function (layoutPartName) {
        var blockContentPieces = contentPieces.filterBy('layoutPartName', layoutPartName).sortBy('displayOrder');
        return Ember.Object.create({
          layoutPartName: layoutPartName,
          contentPieces: blockContentPieces,
          isPrimary: layoutPartName == 'content-block-primary' ? true : false,
          isSecondary: layoutPartName == 'content-block-secondary' ? true : false
        });
      });
    }),
    actions: {
      buttonWasClicked: function buttonWasClicked(contentPiece) {
        this.get('onButtonClick')(contentPiece);
      },
      didShare: function didShare() {
        this.get('onShare').apply(void 0, arguments);
      },
      showHideBackgroundVControls: function showHideBackgroundVControls() {
        if (this.get('layoutBackgroundVideoExists')) {
          var allBackgroundVideoControls = (0, _jquery.default)('.background-image .content-piece-wrapper .layout-bg-video .video-controls');
          var lastController = allBackgroundVideoControls[allBackgroundVideoControls.length - 1];

          if (lastController) {
            if (lastController.style.display === '') {
              lastController.style.display = 'flex';
            } else {
              lastController.style.display = lastController.style.display === 'none' ? 'flex' : 'none';
            }
          }
        }
      },
      socialLoginSubmit: function socialLoginSubmit(formField) {
        this.get('onSocialLogin')(formField);
      },
      submitForm: function submitForm(contentPiece, formSubmission) {
        this.get('onFormSubmission')(contentPiece, formSubmission);
      }
    },

    /*-------- Default functions --------*/
    didRender: function didRender() {
      this.get('onRenderScreen')();
      this.checkForImageOverride();
    },

    /*------------ Functions ------------*/
    checkForImageOverride: function checkForImageOverride() {
      //TODO: This should not be the way we should be doing this, need to grab style overrides instead
      //There are no style overrides for images at the moment which is why we have to do it this way
      var contentCollectionId = this.get('contentCollection.id');
      var layoutImageCheck = ".layout-".concat(contentCollectionId, " > .background-image");
      var primaryImageCheck = ".content-block-primary-".concat(contentCollectionId, " > .background-image");
      var secondaryImageCheck = ".content-block-secondary-".concat(contentCollectionId, " > .background-image");
      var imageChecks = [layoutImageCheck, primaryImageCheck, secondaryImageCheck]; //For each block, check if there is an image

      var checkForImageInBlock = imageChecks.map(function (imageCheck) {
        if ((0, _jquery.default)(imageCheck)[0]) {
          try {
            var backgroundImageCheck = window.getComputedStyle((0, _jquery.default)(imageCheck)[0]).getPropertyValue("background-image");
            return !(backgroundImageCheck === 'none');
          } catch (err) {
            return false;
          }
        } else {
          return false;
        }
      }); //Set these properties so that we can use them to check if we should show the video (if it exists) or not

      this.setProperties({
        imageOverrideLayout: checkForImageInBlock[0],
        imageOverridePrimary: checkForImageInBlock[1],
        imageOverrideSecondary: checkForImageInBlock[2]
      });
    },
    findBackgroundVideo: function findBackgroundVideo(layoutPartName) {
      var contentPieces = this.get('contentPieces');

      if (contentPieces) {
        var previewMode = this.$('.full-area')[0].offsetHeight > this.$('.full-area')[0].offsetWidth ? 'mobile' : 'desktop';
        var mobileDesktopVideo = contentPieces.findBy('layoutPartName', "".concat(layoutPartName, "-").concat(previewMode));
        var generalVideo = contentPieces.findBy('layoutPartName', layoutPartName);
        return mobileDesktopVideo || generalVideo;
      }
    }
  });

  _exports.default = _default;
});