define("moments/router", ["exports", "moments/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('campaign', {
      path: '/:campaign_id'
    }, function () {
      this.route('content', {
        path: 'screen/:content_collection_id'
      });
    });
    this.route('preview', {
      path: 'preview/:campaign_id'
    }, function () {
      this.route('content', {
        path: 'screen/:content_collection_id'
      });
    });
    this.route('not-found');
  });
  var _default = Router;
  _exports.default = _default;
});