define("moments/services/data-branch-handler", ["exports", "platform"], function (_exports, _platform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    queryParams: Ember.inject.service(),
    outcomeHandler: Ember.inject.service(),
    handle: function handle(dataBranch, session) {
      var type = dataBranch.get('type');
      var dataAction;

      if (type === 'Percentage') {
        dataAction = this.handlePercentage(dataBranch);
      } else if (type === 'DeviceType') {
        dataAction = this.handleDeviceType(dataBranch);
      } else if (type === 'QueryParameter') {
        dataAction = this.handleQueryParameter(dataBranch);
      } else if (type === 'Outcome') {
        dataAction = this.handleOutcome(dataBranch, session);
      } else if (type === 'Trait') {
        dataAction = this.handleTrait(dataBranch, session);
      } else if (type === 'LaunchLink') {
        dataAction = this.handleLaunchLink(dataBranch);
      }

      if (dataAction) {
        return dataAction.get('edge');
      }
    },
    handleDeviceType: function handleDeviceType(dataBranch) {
      var isMobile = !!_platform.default.product;
      var desiredValue = isMobile ? 'mobile' : 'desktop';
      return dataBranch.get('dataActions').findBy('value', desiredValue);
    },
    handleOutcome: function handleOutcome(dataBranch, session) {
      var outcome = this.get('outcomeHandler').calculateOutcome(session);
      return dataBranch.get('dataActions').findBy('value', outcome.get('id'));
    },
    handlePercentage: function handlePercentage(dataBranch) {
      var dataActions = dataBranch.get('dataActions');
      var threshold = parseInt(Math.random() * 100);
      var chosenDataAction;
      dataActions.toArray().some(function (dataAction) {
        var value = parseInt(dataAction.get('value'));

        if (threshold <= value) {
          chosenDataAction = dataAction;
          return true;
        }

        threshold -= value;
        return false;
      });
      return chosenDataAction;
    },
    handleQueryParameter: function handleQueryParameter(dataBranch) {
      var dataActions = dataBranch.get('dataActions');
      var queryParams = this.get('queryParams');
      var paramValue = queryParams.findParam(dataBranch.get('value'));
      var selectedDataAction; // Attempt to find the exact param value

      if (paramValue) {
        paramValue = paramValue.toString();
        selectedDataAction = dataActions.findBy('value', paramValue);
      } // If it does not exist, but a value is present, attempt to find a wildcard


      if (paramValue && !selectedDataAction) {
        var wildcard = dataActions.findBy('value', '*');
        if (wildcard) selectedDataAction = wildcard;
      } // Otherwise return the default


      if (!selectedDataAction) {
        selectedDataAction = dataActions.findBy('value', 'default');
      }

      return selectedDataAction;
    },
    handleTrait: function handleTrait(dataBranch, session) {
      var dataActions = dataBranch.get('dataActions');
      var unknown = dataActions.findBy('value', 'unknown');
      var associatedTraitIds = session.get('associatedTraitIds');

      if (!associatedTraitIds || associatedTraitIds.length == 0) {
        return unknown;
      } else if (associatedTraitIds.indexOf(dataBranch.get('value')) > -1) {
        return dataActions.findBy('value', 'known');
      } else {
        return unknown;
      }
    },
    handleLaunchLink: function handleLaunchLink(dataBranch) {
      var dataActions = dataBranch.get('dataActions');
      var queryParams = this.get('queryParams');
      var linkParameter = queryParams.findParam('L');
      var customParamValue = queryParams.findParam('JC');
      linkParameter = linkParameter ? "L=".concat(linkParameter.toString()) : null;
      customParamValue = customParamValue ? "JC=".concat(customParamValue.toString()) : null;
      var dataAction = dataActions.find(function (dataAction) {
        return dataAction.get('distributionLinkData.linkParameter') === linkParameter && dataAction.get('distributionLinkData.customParamValue') === customParamValue;
      });
      return dataAction ? dataAction : dataActions.findBy('value', 'default');
    }
  });

  _exports.default = _default;
});