define("moments/models/content-piece", ["exports", "ember-data", "moments/utils/render-handlebars"], function (_exports, _emberData, _renderHandlebars) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;

  var _default = _emberData.default.Model.extend({
    childContentPieces: hasMany('content-piece', {
      inverse: 'parentContentPiece'
    }),
    livePollingEdgeStat: belongsTo('live-polling-edge-stat'),
    delayAppearance: attr('number'),
    displayOrder: attr('number'),
    contentCollection: belongsTo('content-collection'),
    edgeMessage: belongsTo('edge-message'),
    edge: belongsTo('edge'),
    formFields: hasMany('form-field'),
    layoutPartName: attr('string'),
    newTabRedirect: attr('object'),
    parentContentPiece: belongsTo('content-piece', {
      inverse: 'childContentPieces'
    }),
    type: attr('string'),
    style: attr('string'),
    originalValue: attr('string'),
    jsonValue: attr('object'),
    contentMetadata: Ember.computed.alias('contentPieceSupplement.metadata'),
    feedItem: Ember.computed.readOnly('parentContentPiece.jsonValue.feedItem'),
    feedOutcomePresent: Ember.computed.readOnly('contentCollection.feedOutcomePresent'),
    percentage: Ember.computed.alias('livePollingEdgeStat.percentage'),
    outcome: Ember.computed.readOnly('contentCollection.outcome'),
    isForm: Ember.computed('type', function () {
      var type = this.get('type');
      var formTypes = ['form', 'facebook-login', 'google-login'];
      return formTypes.indexOf(type) >= 0;
    }),
    value: Ember.computed('originalValue', 'outcome', 'jsonValue', 'feedOutcomePresent', 'feedItem', function () {
      var outcomeRegex = /^{ outcome (heading|image|description) }$/;

      var _this$getProperties = this.getProperties(['feedItem', 'feedOutcomePresent', 'jsonValue', 'outcome', 'originalValue']),
          feedItem = _this$getProperties.feedItem,
          feedOutcomePresent = _this$getProperties.feedOutcomePresent,
          jsonValue = _this$getProperties.jsonValue,
          outcome = _this$getProperties.outcome,
          originalValue = _this$getProperties.originalValue;

      var value;
      var jsonValueCopy;
      if (jsonValue) jsonValueCopy = _objectSpread({}, jsonValue); // Dynamic context replacement logic

      if (feedItem || feedOutcomePresent) {
        var dynamicContext = feedItem || outcome;

        if (jsonValue) {
          Object.keys(jsonValue).forEach(function (key) {
            if (jsonValue[key]) {
              jsonValueCopy[key] = (0, _renderHandlebars.default)(jsonValue[key], dynamicContext);
            }
          });
        } else {
          value = (0, _renderHandlebars.default)(originalValue, dynamicContext);
        }
      } // Original outcome replacement logic


      if (outcome && !feedOutcomePresent) {
        if (this.get('type') === 'image' && outcomeRegex.test(jsonValueCopy.url)) {
          jsonValueCopy.url = Ember.get(outcome, 'imageUrl');
        } else if (outcomeRegex.test(originalValue)) {
          var property = originalValue.replace('{ outcome ', '').replace(' }', '');
          value = Ember.get(outcome, property);
        }
      }

      return jsonValueCopy || value || originalValue;
    })
  });

  _exports.default = _default;
});