define("moments/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/FEctkXl",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,\"notification-container\"],false],[0,\"\\n\\n\"],[1,[29,\"overlay-screen\",null,[[\"options\",\"showScreen\"],[[25,[\"overlayScreenManager\",\"options\"]],[25,[\"showOverlayScreen\"]]]]],false],[0,\"\\n\\n\"],[1,[23,\"outlet\"],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/templates/application.hbs"
    }
  });

  _exports.default = _default;
});