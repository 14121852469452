define("moments/components/notification-message/component", ["exports", "ember-cli-notifications/components/notification-message"], function (_exports, _notificationMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _notificationMessage.default.extend({
    classNames: ['c-notification'],
    classNameBindings: ['wrapperClass'],

    /* Override the icon functionality of Ember CLI Notifications */
    notificationIcon: Ember.computed('notification.type', function () {
      var type = this.get('notification.type');
      var icons = {
        check: 'done',
        clear: 'clear',
        'thumb up': 'thumb_up',
        'thumb down': 'thumb_down'
      };
      return icons[type];
    }),
    wrapperClass: Ember.computed('notification.type', function () {
      var type = this.get('notification.type').split(' ').join('-');
      return "c-notification--".concat(type);
    })
  });

  _exports.default = _default;
});