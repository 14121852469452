define("moments/components/content-piece/button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WZqv1M27",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"class\",[30,[\"basic-button \",[23,\"buttonClass\"],\" \",[23,\"styleClass\"]]]],[12,\"style\",[23,\"paddingCSS\"]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"live-polling-wrapper\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[30,[[23,\"widthOrHeightClass\"],\" animation\"]]],[12,\"style\",[23,\"livePollingStyle\"]],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"poll-number\"],[9],[0,\"\\n        \"],[1,[23,\"percentage\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[\"content \",[23,\"contentClass\"]]]],[9],[0,\"\\n    \"],[1,[25,[\"contentPiece\",\"value\"]],true],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"click\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/button/template.hbs"
    }
  });

  _exports.default = _default;
});