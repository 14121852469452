define("moments/components/content-piece/form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RfjTspXg",
    "block": "{\"symbols\":[\"formField\"],\"statements\":[[7,\"form\"],[12,\"class\",[30,[\"content \",[23,\"contentPieceClass\"]]]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"sortedFormFields\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"content-piece/form-field\",null,[[\"formField\",\"onClick\",\"onSocialLoginClick\"],[[24,1,[]],[29,\"action\",[[24,0,[]],\"click\"],null],[29,\"action\",[[24,0,[]],\"socialLoginClick\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/form/template.hbs"
    }
  });

  _exports.default = _default;
});