define("moments/components/content-piece/video/component", ["exports", "moments/components/content-piece/base/component", "moments/config/environment", "platform", "moment"], function (_exports, _component, _environment, _platform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['video-wrapper'],
    bucket: _environment.default.APP.s3.filepickerBucket,
    googleAnalytics: Ember.inject.service('googleAnalytics'),
    mouseIsUp: true,
    playPauseIcon: 'pause',
    muteUnmuteIcon: 'volume_off',
    videoHasLoaded: false,
    videoCurrentTime: '00:00',
    videoDuration: '00:00',
    oldSliderValue: 0,
    businessId: Ember.computed.alias('contentPiece.jsonValue.businessId'),
    fileName: Ember.computed.alias('contentPiece.jsonValue.fileName'),
    fileType: Ember.computed.alias('contentPiece.jsonValue.fileType'),
    video: Ember.computed('videoClass', function () {
      return document.getElementsByClassName(this.get('videoClass'))[0];
    }),
    videoControls: Ember.computed('videoClass', function () {
      return document.getElementsByClassName("".concat(this.get('videoClass'), "-controls"))[0];
    }),
    videoProgressSlider: Ember.computed('videoClass', function () {
      return document.getElementsByClassName("".concat(this.get('videoClass'), "-progress-slider"))[0];
    }),
    isMobile: Ember.computed('contentPiece', function () {
      return !!_platform.default.product;
    }),
    videoClass: Ember.computed('contentClass', function () {
      var contentClass = this.get('contentClass');
      return "video ".concat(contentClass);
    }),
    mobilePath: Ember.computed('contentPiece.jsonValue', function () {
      var _this$getProperties = this.getProperties(['bucket', 'businessId', 'fileName', 'fileType']),
          bucket = _this$getProperties.bucket,
          businessId = _this$getProperties.businessId,
          fileName = _this$getProperties.fileName,
          fileType = _this$getProperties.fileType;

      var link = "https://s3.amazonaws.com/".concat(bucket, "/videos/").concat(businessId, "/output/").concat(fileName, "/mobile/").concat(fileName, ".").concat(fileType);
      return link;
    }),
    desktopPath: Ember.computed('contentPiece.jsonValue', function () {
      var _this$getProperties2 = this.getProperties(['bucket', 'businessId', 'fileName', 'fileType']),
          bucket = _this$getProperties2.bucket,
          businessId = _this$getProperties2.businessId,
          fileName = _this$getProperties2.fileName,
          fileType = _this$getProperties2.fileType;

      var link = "https://s3.amazonaws.com/".concat(bucket, "/videos/").concat(businessId, "/output/").concat(fileName, "/desktop/").concat(fileName, ".").concat(fileType);
      return link;
    }),
    actions: {
      playPauseVideo: function playPauseVideo() {
        var video = this.get('video');

        if (video.paused) {
          video.play();
          this.set('playPauseIcon', 'pause');
          this.sendVideoEvent('play');
        } else {
          video.pause();
          this.set('playPauseIcon', 'play_arrow');
          this.sendVideoEvent('pause');
        }
      },
      muteUnmuteVideo: function muteUnmuteVideo() {
        var video = this.get('video');

        if (video.muted) {
          video.muted = false;
          this.set('muteUnmuteIcon', 'volume_up');
          this.sendVideoEvent('unmute');
        } else {
          video.muted = true;
          this.set('muteUnmuteIcon', 'volume_off');
          this.sendVideoEvent('mute');
        }
      },
      hideControls: function hideControls() {
        var controls = this.get('videoControls');
        controls.style.display = 'none';
      },
      showControls: function showControls() {
        var controls = this.get('videoControls');
        controls.style.display = 'flex';
      },
      showHideControls: function showHideControls() {
        var controls = this.get('videoControls');
        controls.style.display = controls.style.display === 'none' ? 'flex' : 'none';
      }
    },
    didRender: function didRender() {
      var video = this.get('video');
      var slider = this.get('videoProgressSlider');
      var videoHasLoaded = this.get('videoHasLoaded');

      if (video && !videoHasLoaded) {
        video.load();
        this.set('videoHasLoaded', true);
        var self = this;

        video.onloadedmetadata = function () {
          this.muted = true;
          this.play();
          var duration = (0, _moment.default)(this.duration * 1000).format('mm:ss');
          self.set('videoDuration', duration);

          video.ontimeupdate = function () {
            var currentTime = (0, _moment.default)(this.currentTime * 1000).format('mm:ss');
            self.get('updateVideoTime')(self, currentTime);

            if (self.get('mouseIsUp')) {
              slider.value = this.currentTime / this.duration;
              self.set('oldSliderValue', slider.value);
            }
          };

          slider.oninput = function () {
            video.currentTime = video.duration * this.value;
          };

          slider.onchange = function () {
            var oldSliderValue = self.get('oldSliderValue');
            var newSliderValue = this.value;

            if (newSliderValue > oldSliderValue) {
              self.sendVideoEvent('fast forward');
            } else if (oldSliderValue > newSliderValue) {
              self.sendVideoEvent('rewind');
            }

            self.set('oldSliderValue', newSliderValue);
            video.currentTime = video.duration * newSliderValue;
          };
        };
      }
    },
    mouseUp: function mouseUp() {
      this.set('mouseIsUp', true);
    },
    mouseDown: function mouseDown() {
      this.set('mouseIsUp', false);
    },
    updateVideoTime: function updateVideoTime(self, currentTime) {
      self.set('videoCurrentTime', currentTime);
    },
    sendVideoEvent: function sendVideoEvent(event) {
      var layoutPartName = this.get('layoutPartName') ? this.get('layoutPartName') : 'inline-video';
      var ga = this.get('googleAnalytics');
      ga.sendVideoEvent(event, layoutPartName);
    }
  });

  _exports.default = _default;
});