define("moments/mixins/add-assets-to-dom", ["exports", "moments/utils/create-style-tag", "moments/utils/create-script-tag", "jquery"], function (_exports, _createStyleTag, _createScriptTag, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    addAssetsToDom: function addAssetsToDom(campaign) {
      var _campaign$getProperti = campaign.getProperties(['cssUrl', 'jsUrl']),
          cssUrl = _campaign$getProperti.cssUrl,
          jsUrl = _campaign$getProperti.jsUrl;

      if (cssUrl && (0, _jquery.default)("link[href=\"".concat(cssUrl, "\"]")).length === 0) {
        (0, _createStyleTag.default)(cssUrl);
      }

      if (jsUrl && (0, _jquery.default)("script[src=\"".concat(jsUrl, "\"]")).length === 0) {
        (0, _createScriptTag.default)(jsUrl);
      }
    }
  });

  _exports.default = _default;
});