define("moments/components/content-piece/base/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var dasherize = Ember.String.dasherize;

  var _default = Ember.Component.extend({
    classNames: ['content-piece-wrapper'],
    classNameBindings: ['wrapperClass', 'isHidden:hidden'],
    contentPiece: null,
    isHidden: false,
    layoutPartName: null,
    userHasResponded: false,
    contentClass: Ember.computed('contentPiece.id', function () {
      return "content-".concat(this.get('contentPiece.id'));
    }),
    styleClass: Ember.computed('contentPiece.style', function () {
      var style = this.get('contentPiece.style');

      if (style) {
        return "style-".concat(dasherize(style));
      }
    }),
    wrapperClass: Ember.computed('contentPiece.{id,type,style,displayOrder}', 'layoutPartName', function () {
      var contentPiece = this.get('contentPiece');
      var wrapperPrefix = 'content-piece-wrapper';
      var layoutPartName = this.get('layoutPartName');

      var _contentPiece$getProp = contentPiece.getProperties(['id', 'style', 'type', 'displayOrder']),
          id = _contentPiece$getProp.id,
          style = _contentPiece$getProp.style,
          type = _contentPiece$getProp.type,
          displayOrder = _contentPiece$getProp.displayOrder;

      var styleClass = '';
      var typeClass = '';
      var displayOrderClass = '';

      if (type) {
        typeClass = "".concat(dasherize(type), "-wrapper");
      }

      if (style) {
        styleClass = "".concat(dasherize(style), "-wrapper");
      }

      if (layoutPartName && layoutPartName.indexOf('column') > -1) {
        displayOrderClass = "".concat(wrapperPrefix, "-").concat(layoutPartName, "-").concat(displayOrder);
      } else {
        displayOrderClass = "".concat(wrapperPrefix, "-").concat(displayOrder);
      }

      return "".concat(wrapperPrefix, "-").concat(id, " ").concat(typeClass, " ").concat(styleClass, " ").concat(displayOrderClass);
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var delay = this.get('contentPiece.delayAppearance');

      if (delay) {
        this.set('isHidden', true);
        Ember.run.later(function () {
          _this.set('isHidden', false);
        }, delay * 1000);
      }
    }
  });

  _exports.default = _default;
});