define("moments/components/notification-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FW40XI35",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"notificationIcon\"]]],null,{\"statements\":[[0,\" \"],[7,\"div\"],[12,\"class\",[30,[[25,[\"styles\",\"c-notification__icon\"]]]]],[9],[0,\"\\n  \"],[7,\"i\"],[12,\"class\",[30,[\"material-icons md-24 \",[23,\"notificationIcon\"]]]],[9],[1,[23,\"notificationIcon\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\"],[12,\"class\",[30,[[25,[\"styles\",\"c-notification__content\"]],\" notification-message\"]]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"notification\",\"htmlContent\"]]],null,{\"statements\":[[0,\"    \"],[1,[25,[\"notification\",\"message\"]],true],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[25,[\"notification\",\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"notification\",\"autoClear\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[25,[\"styles\",\"c-notification__countdown\"]]]]],[12,\"style\",[23,\"notificationClearDuration\"]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/notification-message/template.hbs"
    }
  });

  _exports.default = _default;
});