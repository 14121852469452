define("moments/components/content-piece/form-field/checkbox-array/component", ["exports", "moments/utils/strip-html-tags"], function (_exports, _stripHtmlTags) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['select-all-that-apply-wrapper', 'layout-column'],
    checkboxes: Ember.computed('formField', function () {
      var labels = this.get('formField.text').split("\n");
      var formFieldId = this.get('formField.id');
      return labels.map(function (text, i) {
        return Ember.Object.create({
          class: "form-field-".concat(formFieldId, " checkbox-array checkbox-array-").concat(i),
          inputId: "input-".concat(formFieldId, "-").concat(i),
          isChecked: false,
          text: text
        });
      });
    }),
    actions: {
      clickCheckbox: function clickCheckbox(checkbox, event) {
        var value = [];
        var newState;

        if (event) {
          newState = event.currentTarget.checked;
        } else {
          newState = !checkbox.get('isChecked');
        }

        checkbox.set('isChecked', newState);
        this.get('checkboxes').forEach(function (option) {
          if (option.get('isChecked')) {
            value.push((0, _stripHtmlTags.default)(option.get('text')));
          }
        });
        this.set('formField.value', value.join("\n"));
      }
    }
  });

  _exports.default = _default;
});