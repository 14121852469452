define("moments/components/campaign-logo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qxclIjlC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[11,\"class\",\"logo\"],[12,\"src\",[30,[[23,\"logoUrl\"]]]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/campaign-logo/template.hbs"
    }
  });

  _exports.default = _default;
});