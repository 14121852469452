define("moments/components/content-piece/social-share/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7IK/0TyM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"social-buttons-wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[\"social-sharing-buttons content-\",[25,[\"contentPiece\",\"id\"]]]]],[9],[0,\"\\n    \"],[1,[29,\"social-button/facebook\",null,[[\"text\",\"onShare\",\"socialData\"],[[25,[\"facebookButtonText\"]],[29,\"action\",[[24,0,[]],\"didShare\"],null],[25,[\"socialSharingMetadata\"]]]]],false],[0,\"\\n\\n    \"],[1,[29,\"social-button/twitter\",null,[[\"text\",\"onShare\",\"socialData\"],[[25,[\"twitterButtonText\"]],[29,\"action\",[[24,0,[]],\"didShare\"],null],[25,[\"socialSharingMetadata\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "moments/components/content-piece/social-share/template.hbs"
    }
  });

  _exports.default = _default;
});